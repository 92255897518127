.matchupButton {
	background-color: #eee !important;
}

.tiebreakerIcon {
    padding-left: 5px;
    display: inline-block;
}

.tooltip > .tooltip-inner {
    max-width: 100%;
  }

  .btn-custom {
    --bs-btn-color: #212529;
    --bs-btn-border-color: #212529;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: lightgray;
    --bs-btn-hover-border-color: #212529;
    --bs-btn-focus-shadow-rgb: 33,37,41;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #212529;
    --bs-btn-active-border-color: #212529;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #212529;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #212529;
    --bs-gradient: none;
}

.playoffTeam {
  --bs-table-bg: #dff0d8 !important;
}